<template>
  <ui-component-modal
    modalTitle="Delete widget"
    :buttonLabelConfirm="$t('Form.Control.Delete')"
    confirmButtonCssClass="is-danger"
    :isSaving="isDeleting"
    :isSavingSuccess="isDeletingSuccess"
    :isSavingError="isDeletingError"
    :hideFooterButtons="isDeletingError || isDeletingSuccess"
    :disableSaveButton="!isDeleteConfirmed"
    :onClickCancel="onClickCancel"
    :onClickSave="deleteSearchWidget"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
      <template slot="modalTitle"></template>
    <template v-slot:content>
      <div>
        <div class="subtitle has-text-centered has-margin-bottom">
          {{$t('Components.Settings.Widgets.ModalDeleteSearchWidget.Text_AreYouSure')}}

          <div class="has-text-weight-bold has-text-centered has-margin-top">{{widget.Name}}</div>
        </div>
        <div class="has-text-centered">
          <BaseCheckbox
            v-model="isDeleteConfirmed"
            class="has-text-left"
            label="Yes, sure"
          />
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import widgetProvider from '@/providers/widget'

const BaseCheckbox = () =>
  import(
    /* webpackChunkName: "settings-options" */ '@/components/UI/Form/BaseCheckbox'
  )

export default {
  components: {
    BaseCheckbox,
  },

  props: {
    widget: {
      type: Object,
      default: null,
    },

    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isDeleteConfirmed: false,
      isDeleting: false,
      isDeletingSuccess: false,
      isDeletingError: false,
    }
  },

  computed: {},

  created() {},

  methods: {
    deleteSearchWidget() {
      let self = this

      if (!self.isDeleting) {
        self.isDeleting = true

        widgetProvider.methods
          .deleteLocationSearchWidgets(self.widget)
          .then((response) => {
            if (response.status === 204) {
              self.isDeletingSuccess = true
              self.$emit('widgetDeleted', self.widget)

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((error) => {
            self.isDeletingError = true
          })
          .finally(() => {
            self.isDeleting = false
          })
      }
    },
  },
}
</script>
